<template>
  <b-row class="filters-container">
    <b-col cols="2">
      <Periods id="periods" @select="handlePeriod" />
    </b-col>

    <b-col cols="2">
      <b-form-input
        id="batch_number"
        placeholder="Nº Lote"
        v-model="filters.batch_number"
        debounce="500"
      />
    </b-col>

    <b-col cols="2">
      <b-form-input
        id="invoice_number"
        debounce="500"
        placeholder="Nº Folha"
        v-model="filters.sheet_number"
      />
    </b-col>

    <b-col cols="2">
      <b-button id="btn-apply-filter" variant="outline-primary">Aplicar</b-button>
    </b-col>
  </b-row>
</template>

<script>

export default {
  components: {
    Periods: () => import('@/components/General/Periods'),
  },
  data() {
    return {
      filters: {
        batch_number: '',
        sheet_number: '',
        period: {
          type: 'create',
          start: '',
          end: ''
        }
      }
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }
}
#btn-apply-filter{
  background: var(--surface-tertiary, #E7ECFE);
  border-color: var(--surface-tertiary, #E7ECFE);
  width: 100%;
}
</style>
